import Modal from './modal'
import { InteractionLoader } from './interactionLoader';

export default class Header {
    constructor() {
        this.container = document.body
        this.actions()

        new InteractionLoader({
            'analytics': {
                'url': 'https://www.googletagmanager.com/gtag/js?id=G-YC7EQQK7GC',
                'onload': () => {
                    window.dataLayer = window.dataLayer || [];
                    window.gtag = () => { dataLayer.push(arguments); }
                    gtag('js', new Date());
                    gtag('config', 'G-YC7EQQK7GC');
                    gtag('event', 'page_view', {
                        page_title: document.title,
                        page_location: document.location.href.split('.html')[0]
                    });
                }
            },
            'fonts': {
                'url': 'https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap',
                'type': 'font'
            }
        })

        let canonical = document.createElement('link')
        canonical.setAttribute('rel', 'canonical')
        canonical.setAttribute('href', document.location.href.split('.html')[0])
        document.head.appendChild(canonical)
    }

    actions() {
        this.modal = new Modal(`
            <div class="form">
                <input class="f-name" type="text" placeholder="Your name" name="name" />
                <input class="f-mail" type="email" placeholder="Your email" name="email" />
                <input class="f-size" type="text" placeholder="Business size (ex: 60)" name="size" />
                <button class="modal-submit" type="submit">Send</button>
            </form>
        `)
        this.modal.container.querySelector('.modal-submit').onclick = () => {
            fetch('/.netlify/functions/submit', {
                method: 'POST',
                body: JSON.stringify({
                    name: this.modal.container.querySelector('.f-name').value,
                    mail: this.modal.container.querySelector('.f-mail').value,
                    size: this.modal.container.querySelector('.f-size').value,
                    page: document.title,
                    type: 'form'
                })
            }).then(r => r.json()).then(res => {
                this.modal.hide()
                console.log(res)
            })
        }
        document.querySelector('[data-modal=signup]').onclick = () => this.modal.show()
    }
}