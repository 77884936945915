import '../css/index.scss';
import Modal from '../components/modal'
import '../assets/favicon.ico'
import { InteractionLoader } from '../components/interactionLoader';
import Header from '../components/header';

const header = new Header()

new (class Index {
    constructor() {
        
    }
})()